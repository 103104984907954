<!-- =========================================================================================
    File Name: Listquestions.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/question/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card bg-dark">
    <h4 class="pl-8 pt-6" v-if="title">{{ title }}</h4>
    <div class="p-8 pt-4">
      <div class="flex flex-wrap justify-between">
        <div class="flex">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium mr-2"
            >
              <span>
                {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
                {{ questionData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : questionData.length }} of
                {{ totalQuestions }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(10);
                  loadProductBuffer();
                "
              >
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(20);
                  loadProductBuffer();
                "
              >
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(50);
                  loadProductBuffer();
                "
              >
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(100);
                  loadProductBuffer();
                "
              >
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(150);
                  loadProductBuffer();
                "
              >
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!--- <vs-button color="primary" class="ml-2 round" type="gradient"
                      @click="uploadQuestions">Upload Questions
           </vs-button> --->
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex items-center">
          <vs-input
            @input="updateSearchQuery"
            class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            placeholder="Search..."
            v-model="searchQuery"
          />
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="questionData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable"
      >
      </ag-grid-vue>
      <vs-pagination :total="totalPages" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import TableActions from './components/TableActions.vue';
import TableCopy from './components/TableCopy';
import TableImage from './components/TableImage';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableActions,
    TableCopy,
    TableImage,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    filterButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    filterSets: {
      type: Boolean,
      default: true,
      required: false,
    },
    setId: {
      type: String,
      default: '',
      required: false,
    },
    bankId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      showArchived: false,

      questionData: [],
      totalQuestions: 0,

      pageValue: 0,

      searchQuery: '',

      filterPopup: false,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },

      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: 'Type',
          field: 'type',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: 'Question',
          field: 'question',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableCopy',
        },
        {
          headerName: '',
          field: 'image',
          width: 100,
          filter: false,
          cellRendererFramework: 'TableImage',
        },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableActions,
        TableCopy,
        TableImage,
      },
      totalPages: 0,
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        return this.pageValue + 1;
      },
      set(val) {
        this.pageValue = val - 1;
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    uploadQuestions() {
      this.$router.push('/a/examedge/questions/upload');
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 1;
    },
    loadProductBuffer(page = 0) {
      this.$emit('refresh_data');

      if (page === 0) {
        page = this.currentPage;
      }

      this.gridApi.setId = this.setId;
      this.gridApi.bankId = this.bankId;

      this.$http
        .get(`examedge/question?pageSize=${this.paginationPageSize}&page=${page}&search=${this.searchQuery}&bank=${this.bankId}`)
        .then((response) => {
          if (response.data) {
            this.questionData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalQuestions = response.data.total;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading questions';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load questions',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
};
</script>
<style lang="scss">
.theme-dark {
  #QuestionFilterPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }

  #RegQuestionPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }

    .vs-component.vs-con-textarea.vs-textarea-primary {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
