<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <!-- <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" /> -->
    <feather-icon
      @click="confirmRemoveQuestionFromBankRecord"
      icon="DeleteIcon"
      svgClasses="h-5 w-5 hover:text-danger cursor-pointer "
      v-if="params.api.bankId !== ''"
    />
  </div>
</template>

<script>
export default {
  methods: {
    editRecord() {
      this.$router.push(`/a/examedge/questions/${this.params.data.id}/edit`).catch(() => {});
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Delete',
        text: 'Please confirm you want to delete this question.',
        accept: this.deleteRecord,
        acceptText: 'Delete',
      });
    },
    deleteRecord() {
      this.$vs.loading();

      this.$http
        .delete(`testprep/questions/${this.params.data.id}`)
        .then((response) => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.params.loadProductBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Question Deleted',
              text: 'You have successfully deleted this question',
              position: 'top-right',
            });
          }

          this.showError({ response });
        })
        .catch(this.showError);
    },
    confirmRemoveQuestionFromBankRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Remove Question From Bank',
        text: 'Please confirm you want to remove this question from the bank',
        accept: this.removeQuestionFromBankRecord,
        acceptText: 'Confirm',
      });
    },
    removeQuestionFromBankRecord() {
      this.$vs.loading();

      this.$http
        .patch(`examedge/bank/${this.params.api.bankId}/questions`, { removeIds: [this.params.data.id] })
        .then((response) => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.params.loadProductBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Question Removed From Bank',
              text: 'You have successfully removed this question from the bank',
              position: 'top-right',
            });
          }
          this.showError({ response });
        })
        .catch(this.showError);
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this question';
      if (exception.response) {
        error = exception.response.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive question',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
