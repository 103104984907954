<template>
  <div class="flex items-center justify-center h-full w-full">
    <div v-if="params.data.question_image">
      <lottie-animation v-if="params.data.question_image.endsWith('.json')" :path="params.data.question_image" :loop="true" :autoPlay="true" :speed="1" class="cursor-pointer"/>
      <img v-else :src="params.data.question_image" width="210" class="rounded-lg cursor-pointer" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
    </div>
    <div v-if="params.data.definition_image">
      <lottie-animation v-if="params.data.definition_image.endsWith('.json')" :path="params.data.definition_image" :loop="true" :autoPlay="true" :speed="1" class="cursor-pointer"/>
      <img v-else :src="params.data.definition_image" width="210" class="rounded-lg cursor-pointer" alt="" @click.stop v-viewer.static="{toolbar: false, title: false}">
    </div>

  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

export default {
  directives: {
    viewer: viewer()
  }
}
</script>
